<template>
	<div class="LogInWidget">
		<button class="DriverButton SignInButton" @click="signInClient()">
			Sign in as client
		</button>
	</div>
</template>

<script lang="ts" src="./log-in.ts"></script>
<style lang="scss" src="./log-in.scss"></style>
