<template>
	<div class="LogOutWidget">
		<button class="NavigatorButton LogOutButton" @click="signOut()">
			Sign out
		</button>
		<button class="Textlink LogOutDismissButton" @click="hideWidget()">
			Hide
		</button>
	</div>
</template>

<script lang="ts" src="./log-out.ts"></script>
<style lang="scss" src="./log-out.scss"></style>
