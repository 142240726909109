<template>
	<div class="WhiteBlock">
		<div class="ACTDashboardContentWrapper">
			<div class="ACTDashboardTextWrapper">
				<h2 class="ACTDashboardTitle">
					Empower Your Institution with Data-Driven Decisions!
				</h2>
				<p class="ACTDashboardText">
					It&apos;s not just data; it&apos;s a story waiting to be told.
					Our dashboards translate millions of data points into narratives
					about student interests, regional diversity, emerging trends, and more.
					Shape your institution&apos;s future by leveraging real-time student demand data.
				</p>
				<a
					@click="trackClickBeforeRedirect"
					class="ChampionButton ACTDashboardButton"
					href="https://studyportals.typeform.com/to/LrQccAJb"
					target="_act_leads"
				>
					Get in touch
				</a>
			</div>
			<img
				class="ACTDashboardImage ACTDashboardLeadsImage"
				src="//monet-prtl-co.imgix.net/Endor/ACT/act-leads-visualisation.svg"
				alt="Empowerment of data-driven decisions"
			/>
		</div>
	</div>
</template>

<script lang="ts" src="./leads-block.ts"></script>
<style lang="scss" src="./leads-block.scss"></style>
