<template>
	<div class="WhiteBlock">
		<div class="ACTDashboardContentWrapper">
			<div>
				<h2 class="ACTDashboardTitle">Don't guess, ACT on insights</h2>
				<p class="ACTDashboardText">
					The ACT team can elevate your institution's international marketing
					and student recruitment strategy with expertise, tools, and knowledge,
					helping you excel in higher education's competitive landscape.
				</p>
			</div>
			<img
				class="ACTDashboardImage"
				src="//monet-prtl-co.imgix.net/Endor/ACT/act-data-visualisation.svg"
				alt="ACT Data Visualisation"
			/>
		</div>
	</div>
</template>

<script lang="ts" src="./introduction.ts"></script>
<style lang="scss" src="./introduction.scss"></style>
